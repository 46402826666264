import React from "react";
import { Link } from "react-router-dom";

import Dot from "./dot";

const Footer = () => {
  // write a fucntion that gets the current year
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div
        className="container-fluid top-footer pt-5"
        style={{
          backgroundColor: "rgba(255, 245, 245, 1)",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h4 className="text-center mb-2 ">
          Stay Informed, Stay Healthy! <Dot color={"#D21D22"} />{" "}
        </h4>
        <div className="col-md-5 text-center p5-5 lh-28">
          Subscribe to our newsletter and receive the latest updates, health
          tips, and exclusive offers directly to your inbox. Join our community
          for a journey towards optimal well-being.
        </div>
        <div className="col-md-3 mt-20">
          <input
            type="email"
            className="form-control mb-3"
            placeholder="Enter email address"
          />
          <button type="button" className="btn btn-danger form-control">
            Subscribe
          </button>
        </div>
      </div>

      <div
        style={{ backgroundColor: "rgb(229 229 229)" }}
        className="container-fluid"
      >
        <div className="row footer-bottom ">
          <div className="col-md-4 ml-40 pt-5">
            <img
              alt=""
              src="images/logo.png"
              className="img-fluid mb-20"
              width="55"
              height="68"
            />
            <div className="mb-20 lh-28">
              Welcome to Philips Diagnostic Centre, Akure, nestled in the heart
              of Ondo State, Nigeria. At our state-of-the-art facility, we
              prioritize your health and well-being through cutting-edge
              Diagnostic services. Founded with a commitment to excellence, we
              bring together a team of skilled healthcare professionals and
              advanced technology to provide accurate and timely Diagnostic
              solutions.
            </div>
            <div className="d-none d-md-block">
              <a href="https://www.facebook.com/profile.php?id=61554965094535">
                <img
                  alt=""
                  src="images/icon/facebook.png"
                  className="img-fluid mb-20 me-2"
                  width="30"
                  height="30"
                />
              </a>
              <a href="https://www.instagram.com/philipsDiagnosticcenter/">
                <img
                  alt=""
                  src="images/icon/instagram.png"
                  className="img-fluid mb-20 me-2"
                  width="30"
                  height="30"
                />
              </a>
              <a href="https://www.linkedin.com/company/philips-diagnostic-center/">
                <img
                  alt=""
                  src="images/icon/linkedin.png"
                  className="img-fluid mb-20 me-2"
                  width="30"
                  height="30"
                />
              </a>
              <a href="https://twitter.com/PhilipsDXCenter">
                {" "}
                <img
                  alt=""
                  src="images/icon/x.png"
                  className="img-fluid mb-20"
                  width="30"
                  height="30"
                />
              </a>
            </div>
          </div>
          <div className="col-md-4 ml-40 flex">
            <div className="col-md-6 ps-md-3">
              <h6 className="mt-50">Quick Links</h6> <br />
              <Link className="link" to="/">
                Home
              </Link>{" "}
              <p />
              <Link className="link" to="/services">
                Services
              </Link>{" "}
              <p />
              <Link className="link" to="/about">
                About Us
              </Link>{" "}
              <p />
              <Link className="link" to="/contact">
                Contact Us
              </Link>{" "}
              <p />
            </div>
            <div className="col-md-6 ps-md-5">
              <h6 className="mt-50">Explore</h6> <br />
              <Link className="link" to="/privacy">
                Privacy Policy
              </Link>{" "}
              <p />
              <Link className="link" to="/faq">
                FAQs
              </Link>{" "}
              <p />
            </div>
          </div>
          <div className="col-md-3">
            <h6 className="mt-50">Contact Information</h6> <br />
            <p> Name: Philips Diagnostic Center </p>
            <div className="mt-3">
              <a
                className="link"
                href="https://maps.app.goo.gl/H3QcixfgybVGWNVh9"
              >
                Address: No 5Ob, Alafe Junction, Along Idanre Road, Oke Aro
                Street, Akure, Ondo State. Nigeria.
              </a>
            </div>
            <div className="mt-3">
              <a className="link" href="tel:+2349135002227">
                {" "}
                Phone Number: +2349135002227, +2349135002226
              </a>
            </div>
            <div className="mt-3">
              <a className="link" href="mailto:info@philipsdiagnosticcenter.com">
                {" "}
                Email Address: info@philipsdiagnosticcenter.com
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center copyright">
            Copyright © {currentYear} Philips Diagnostic Center. All rights
            reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
