import React from "react";


const Container = ({color, title, value, fontColor, img}) => {
    return (
        <div className="col">
            <div className="card"  style={{ backgroundColor: color}}>
                <div className="card-body">
                    <span className="grey">{title}</span>
                    <p className="typo" style={{ color: fontColor}}>
                        {value}
                        <img alt="" src={img} className="imgIcon" />
                    </p>
                </div>
            </div>
        </div>
    );
}


export default Container;