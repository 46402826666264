import React from "react";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";

const DNA = () => {
  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>DNA-philipsDiagnostic.com</title>
        <link rel="canonical" href="/dna" />
      </Helmet>
      <Header />
      <div className="container-fluid dna-header pt-3">
        <div className="row">
          <div className="col-md-4 offset-md-2 pt-5 mt-5 dna-blur">
            <h1 className="service-title">DNA Testing</h1>
            <div className="service-subTitle">
              Explore the power of DNA testing at our Diagnostic center. Uncover
              personalized insights into your health, ancestry, and potential
              risks. Your genetic code holds the key to a proactive and informed
              approach to well-being
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselEp"
        className="carousel carousel-light slide mt-50 mb-50"
        data-bs-ride="carousel"
        data-bs-wrap="true"
      >
        <div className="carousel-inner">
          {/* slider item 1 */}
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="container">
              <div className="row ">
                <div className="col-md-4 offset-md-1">
                  <h6 className="mt-20 d-md-none d-lg-none text-dark">
                    DNA Testing <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <img
                    alt=""
                    src="images/dna-testing.png"
                    className="img-fluid mt-3"
                  />
                </div>
                <div className="col-md-6  ps-md-5">
                  <h6 className="mt-20 d-none d-md-block text-dark">
                    DNA Testing <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <div className="text-start mt-2 text-dark ps-sm-2 pe-sm-2 mt-3-sm lh-28">
                    We employ advanced DNA Diagnostic services to provide
                    precise insights into genetic information. Our innovative
                    technology allows for comprehensive genetic testing, aiding
                    in the identification of potential risks, hereditary
                    conditions, and personalized treatment approaches. Whether
                    for proactive health management, understanding familial
                    health patterns, or addressing specific medical concerns,
                    our DNA services offer a thorough examination of genetic
                    material. With a commitment to accuracy, confidentiality,
                    and patient-centric care, Philips Diagnostic Center pioneers
                    the utilization DNA Diagnostic to empower individuals with
                    valuable genetic information for informed healthcare
                    decisions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> */}
      </div>
      <div className="mt-50 mb-50" style={{ height: 50 }}></div>
      <Footer />
    </div>
  );
};

export default DNA;
