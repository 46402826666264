import React, { useState } from "react";
// import { Link } from "react-router-dom";
import {
  Header,
  //   Slider,
  //   Container,
  //   Dot,
  // Testimony,
  Footer,
} from "../../custom";
import { Helmet } from "react-helmet";
import check from "../../Assets/check.svg";
import IndividualPlan from "./IndividualPlan";

const HealthCarePackages = () => {
  const [cooperatePlan, setCooperatePlan] = useState(true);
  const [individualPlan, setIndividualPlan] = useState(false);

  const handleCooperateToggle = () => {
    setCooperatePlan(true);
    setIndividualPlan(false);
  };

  const handleIndividualToggle = () => {
    setIndividualPlan(true);
    setCooperatePlan(false);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - philipsDiagnostic.com</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <Header />
      <div className="container bg-white mt-50 mb-50">
        <div>
          <h2>Health Care Packages</h2>
          <p>
            Your well-being, our priority: Explore our comprehensive healthcare
            packages tailored to meet your needs
          </p>
          <div className="flex justify-content-between offerTab">
            <div
              style={
                cooperatePlan
                  ? {
                      color: "white",
                      backgroundColor: "#017DB9",
                    }
                  : null
              }
              onClick={handleCooperateToggle}
              className="cooperateCont"
            >
              <p style={{ marginBottom: "0" }}>Corporate Health Plan</p>
            </div>
            <div
              style={
                individualPlan
                  ? {
                      color: "white",
                      backgroundColor: "#017DB9",
                    }
                  : null
              }
              onClick={handleIndividualToggle}
              className="cooperateCont"
            >
              <p style={{ marginBottom: "0" }}>Individual Health Plan</p>
            </div>
          </div>
        </div>

        {individualPlan ? (
          <IndividualPlan />
        ) : (
          <div>
            <div className="flex mt-5 planContainer">
              <div className="testCont p-3">
                <h4 className="planName">Domestic staff plan</h4>
                <p>
                  Elevate the health and well-being of your domestic staff with
                  our tailored healthcare plan
                </p>
                <p>Tests</p>
                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Full Blood count</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hiv I and II Rapid </h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Urine Analysis</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hepatitis B s</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Blood group and RH</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hepatitis A</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Sputum</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Stool Examination</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Urine Drug Abuse test</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Xray chest</h5>
                </div>
              </div>

              <div className="testCont p-3">
                <h4 className="planName">Pre-school medical check</h4>
                <p>
                  Ensure the bright start of every child's educational journey
                  with our specialized pre-school medical check-up.
                </p>
                <p>Tests</p>
                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Full Blood count</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hb Genotype </h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Stool Routine</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Urine Analysis</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Blood group and RH</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Sputum</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Xray chest</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Eye test</h5>
                </div>
              </div>

              <div className="testCont p-3">
                <h4 className="planName">Pre-Employment Package</h4>
                <p>
                  Safeguard your business and employees with our comprehensive
                  pre-employment healthcare package
                </p>
                <p>Tests</p>
                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Full Blood count</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Blood group and RH</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Sputum</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hepatitis B s</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Urine Analysis</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Xray chest</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Pregnancy Test </h5>
                </div>
              </div>
            </div>

            <div className="flex mt-5 planContainer">
              <div className="testCont p-3">
                <h4 className="planName">Pre-Marital Check-Up</h4>
                <p>
                  Prepare for a lifetime of happiness with our thorough
                  pre-marital health assessment
                </p>
                <p>Tests</p>
                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Full Blood count</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hiv test</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hepatitis A and B s</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Pelvis Scan</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Blood group and RH</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hb Genotype</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Pregnancy test</h5>
                </div>
              </div>

              <div className="testCont p-3">
                <h4 className="planName">Food Handler Package</h4>
                <p>
                  Protect your food service establishment and customers with our
                  targeted healthcare package for food handlers
                </p>
                <p>Tests</p>
                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Hepatitis A and B surface Antigen</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Stool test</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Urine test</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Ear Test</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Nail scrapping Microscopy</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Sputum</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>WIDAL (Typhoid test)</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Left and right eye test</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>
                    Screening for Methicillin resistant staphylococcus Aureus
                  </h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Tuberclin test</h5>
                </div>
              </div>

              <div className="testCont p-3">
                <h4 className="planName">Chemical Handler Package</h4>
                <p>
                  Mitigate risks and promote well-being in chemical handling
                  environments with our specialized healthcare package
                </p>
                <p>Tests</p>
                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Spirometry</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Uric Acid </h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Full Blood count</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Xray chest PA</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>General physical fitness report</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>Liver Function test</h5>
                </div>

                <div className="flex justify-content-start testTypeCont">
                  <img width="30" className="mr-3" alt="" src={check} />
                  <h5>EUCR</h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default HealthCarePackages;
