import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import logoName from "../Assets/logoName.svg";

const Header = () => {
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="main-header  p0">
            <p className="text-center text-white details me-md-5">
              {" "}
              <FaLocationDot /> No 5Ob, Alafe Junction, Along Idanre Road, Oke
              Aro Street, Akure, Ondo State. Nigeria.{" "}
              <div className="ms-3 phoneNumber">
                <FaPhone />{" "}
                <a href="tel:+2349135002227" className="dialer">
                  +2349135002227
                </a>
                <a
                  style={{ marginLeft: "7px" }}
                  href="tel:+2349135002226"
                  className="dialer"
                >
                  +2349135002226
                </a>
              </div>
            </p>
          </div>
        </div>
      </div>
      <nav
        style={{ backgroundColor: "#F6FDFF" }}
        className="mainBg navbar navbar-expand-lg navbar-light"
      >
        <div style={{ justifyContent: "space-between" }} className="container">
          <a
            style={{ display: "flex", alignItems: "center" }}
            className="navbar-brand"
            href="/"
          >
            <img
              src="images/logo.png"
              alt=""
              width="60"
              height="75"
              className="d-inline-block align-text-top"
            />
            <span style={{}}>
              <img alt="" src={logoName} />
            </span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            style={{ flexGrow: "0" }}
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item marginH">
                <Link
                  className={pathname === "/" ? "nav-link active" : "nav-link"}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item marginH">
                <Link
                  className={
                    pathname === "/about" ? "nav-link active" : "nav-link"
                  }
                  to="/about"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    pathname === "/services" ||
                    pathname === "/care" ||
                    pathname === "/pathology" ||
                    pathname === "/ambulance" ||
                    pathname === "/drug" ||
                    pathname === "/imaging" ||
                    pathname === "/dialysis" ||
                    pathname === "/dna-testing"
                      ? "nav-link marginH active"
                      : "marginH nav-link"
                  }
                  to="/services"
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    pathname === "/healthcare-packages"
                      ? "marginH nav-link active"
                      : "marginH nav-link"
                  }
                  to="/healthcare-packages"
                >
                  Health Care Packages
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    pathname === "/gallery"
                      ? "marginH nav-link active"
                      : "marginH nav-link"
                  }
                  to="/gallery"
                >
                  Gallery
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link
                  className={
                    pathname === "/contact"
                      ? "marginH nav-link active"
                      : "marginH nav-link"
                  }
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li> */}
            </ul>
          </div>

          <div className="row btn-cont">
            <div className="col-md-9">
              {" "}
              <a
                className="btn btn-major"
                type="submit"
                href="https://portal.philipsDiagnosticcenter.com/auth/login"
              >
                Login
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
