import React from "react";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";

const Imaging = () => {
  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Medical - philipsDiagnostic.com</title>
        <link rel="canonical" href="/medical" />
      </Helmet>
      <Header />
      <div className="container-fluid medical-header pt-3 pb-5">
        <div className="row">
          <div className="col-md-4 offset-md-7 pt-5 medical-blur">
            <h1 className="service-title">Medical Imaging</h1>
            <div className="service-subTitle">
              Our advanced medical imaging services bring your health into
              focus, providing precise insights for informed decisions and a
              pathway to a healthier tomorrow.
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-4 ps-5-md pt-2 order-md-2">
            <h6 className="mt-20 d-md-none d-lg-none">
              Medical Imaging <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className="text-danger  d-md-none d-lg-none">Digital X-Ray</h4>
            <img alt="" src="images/xray.png" className="img-fluid mt-3" />
          </div>
          <div className="col-md-5 offset-md-1 order-md-1">
            <h6 className="mt-20 d-none d-md-block ps-sm-2 pe-sm-2">
              Medical Imaging <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className="text-danger d-none d-md-block ps-sm-2 pe-sm-2">
              Digital X-Ray
            </h4>
            <div className="text-start mt-4-sm lh-mobile ps-sm-2 pe-sm-2">
              X-Ray imaging offers a detailed perspective of specific anatomical
              regions associated with patient discomfort or pain. It serves as a
              valuable tool for monitoring the development of various medical
              conditions, such as osteoporosis, and assessing the efficacy of
              treatment methods. This non-invasive technique allows healthcare
              professionals to visualize internal structures, providing
              essential information without the need for surgical incisions.
              X-rays play a crucial role in Diagnostic processes, enabling
              healthcare providers to observe and analyze areas of concern,
              facilitating informed decisions for ongoing medical management and
              ensuring optimal patient care.
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <h6 className="mt-20 d-md-none d-lg-none">
              Medical Imaging <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className="text-danger  d-md-none d-lg-none">CT Scan</h4>
            <img alt="" src="images/scan.png" className="img-fluid mt-2" />
          </div>
          <div className="col-md-6  ps-5-md">
            <h6 className="mt-20 d-none d-md-block">
              Medical Imaging <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className="text-danger d-none d-md-block">CT Scan</h4>
            <div className="text-start mt-2 mt-4-sm lh-mobile pe-sm-2">
              Distinguished from conventional X-rays, a CT scan offers an
              enhanced and comprehensive visual representation of virtually all
              body parts. This advanced imaging technique goes beyond
              surface-level insights, delving into intricate details. CT scans
              play a pivotal role in diagnosing diseases or injuries and are
              instrumental in formulating effective medical, surgical, or
              radiation treatment plans. The intricacy of CT imaging allows
              healthcare professionals to meticulously examine internal
              structures, facilitating a thorough understanding of the patient's
              condition. This in-depth information aids in making informed
              decisions regarding the most suitable and tailored approaches to
              medical interventions, ensuring precision and optimal outcomes for
              individuals undergoing Diagnostic or treatment processes.
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-4 ps-5-md pt-2 order-md-2">
            <h6 className="mt-20 d-md-none d-lg-none">
              Medical Imaging <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className="text-danger d-md-none d-lg-none">MRI</h4>
            <img alt="" src="images/mri.png" className="img-fluid mt-5" />
          </div>
          <div className="col-md-5 offset-md-1 order-md-1">
            <h6 className="mt-20 d-none d-md-block">
              Medical Imaging <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className="text-danger d-none d-md-block">MRI</h4>
            <div className="text-start mt-2 mt-4-sm lh-mobile pe-sm-2">
              At Philips Diagnostic Center, we offer Magnetic Resonance Imaging
              (MRI) services that utilize state-of-the-art technology to provide
              detailed and accurate images of the internal structures of the
              body. Our MRI services contribute to comprehensive Diagnostic
              evaluations, offering a non-invasive and radiation-free method to
              visualize various tissues, including the brain, joints, spine, and
              soft tissues. With a focus on precision and patient comfort, our
              experienced team ensures a thorough examination, aiding in the
              early detection and diagnosis of medical conditions. Philips
              Diagnostic Center's MRI services exemplify our commitment to
              cutting-edge technology, excellence in Diagnostic, and delivering
              high-quality, patient-centric care.
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <h6 className="mt-20 d-md-none d-lg-none">
              Medical Imaging <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className="text-danger d-md-none d-lg-none mb-2">
              Mammography
            </h4>
            <img alt="" src="images/mamo.png" className="img-fluid mt-3" />
          </div>
          <div className="col-md-6 ps-5-md">
            <h6 className="mt-20 d-none d-md-block">
              Medical Imaging <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className="text-danger d-none d-md-block">Mammography</h4>
            <div className="text-start mt-2 mt-4-sm lh-mobile pe-sm-2">
              This is a specialized form of medical imaging, employs a low-dose
              x-ray system to provide a comprehensive view inside the breasts. A
              mammogram, the examination conducted through mammography, plays a
              crucial role in the early detection and diagnosis of breast
              diseases in women. By capturing detailed images of breast tissue,
              mammography assists healthcare professionals in identifying
              abnormalities, potential tumors, or other concerning conditions at
              their nascent stages. This proactive approach enhances the
              effectiveness of medical interventions, promoting timely and
              targeted treatment strategies for optimal patient outcomes.
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselEp"
        className="carousel carousel-light slide mt-50 mb-50"
        data-bs-ride="carousel"
        data-bs-wrap="true"
      >
        <div className="carousel-inner">
          {/* slider item 1 */}
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="container">
              <div className="row ">
                <div className="col-md-3 order-md-2">
                  <h6 className="mt-20 d-md-none d-lg-none">
                    Medical Imaging <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <h4 className="text-danger d-md-none d-lg-none">
                    Ultrasound/Colour Doppler
                  </h4>
                  <img
                    alt=""
                    src="images/ultrasound.png"
                    className="img-fluid mt-5"
                  />
                </div>
                <div className="col-md-6 offset-md-1 text-start text-black order-md-1">
                  <h6 className="mt-20 d-none d-md-block">
                    Medical Imaging <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <h4 className="text-danger d-none d-md-block">
                    Ultrasound/Colour Doppler
                  </h4>
                  <div className="mt-3 mt-4-sm lh-mobile pe-sm-2">
                    This Diagnostic medical imaging method, employs reflected
                    sound waves to visualize the real-time tomographic images of
                    blood flow within blood vessels, muscles, tendons, and
                    various internal organs. This technique provides detailed
                    insights into the size, structure, and potential
                    pathological lesions of these anatomical structures.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> */}
      </div>
      <div className="mt-50 mb-50" style={{ height: 50 }}></div>
      <Footer />
    </div>
  );
};

export default Imaging;
