import React from 'react';

const Dot = ({color}) => {

  return (
    <div className='dotStyle' style={{backgroundColor: color}}></div>
  );
};

export default Dot;
