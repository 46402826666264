import React from "react";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";

const Ambulance = () => {
  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ambulance - philipsDiagnostic.com</title>
        <link rel="canonical" href="/ambulance" />
      </Helmet>
      <Header />
      <div className="container-fluid ambulance-header">
        <div
          style={{ backgroundColor: "rgba(0,0,0,0.3)", height: "100%" }}
          className="row"
        >
          <div className="col-md-4 offset-md-2 pt-5 mt-5 ambulance-blur">
            <h1 className="service-title">Ambulance Service</h1>
            <div className="service-subTitle">
              Our Ambulance Service is dedicated to providing immediate, expert
              medical assistance when it matters most. Count on us for rapid,
              reliable transportation and professional, compassionate care
              during critical moments
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselEp"
        className="carousel carousel-light slide mt-50 mb-50"
        data-bs-ride="carousel"
        data-bs-wrap="true"
      >
        <div className="carousel-inner">
          {/* slider item 1 */}
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="container">
              <div className="row ">
                <div className="col-md-4 offset-md-1">
                  <h6 className="mt-20 d-md-none d-lg-none text-dark">
                    Ambulance Service <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <img
                    alt=""
                    src="images/ambulance-service.jpg"
                    className="img-fluid mt-3"
                  />
                </div>
                <div className="col-md-6  ps-md-5">
                  <h6 className="mt-20 d-none d-md-block text-dark">
                    Ambulance Service <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <div className="text-start mt-2 text-dark pe-sm-2 mt-3-sm lh-28">
                    Philips Diagnostic Center offers a dedicated ambulance
                    service to ensure the swift and safe transportation of
                    patients when needed. Our ambulance service is equipped with
                    advanced medical facilities and operated by a team of
                    skilled and experienced healthcare professionals. Whether
                    it's an emergency situation or a scheduled medical transfer,
                    our ambulance service prioritizes the well-being and comfort
                    of patients. We adhere to stringent safety protocols and
                    provide timely response, ensuring that patients receive the
                    necessary medical attention during transit. Trust Philips
                    Diagnostic Center for reliable and efficient ambulance
                    services, contributing to optimal patient care.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> */}
      </div>
      <div className="mt-50 mb-50" style={{ height: 50 }}></div>
      <Footer />
    </div>
  );
};

export default Ambulance;
