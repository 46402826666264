import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router  } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { history } from './custom';
import Root from './screens/routes';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router forceRefresh history={history}>
      < Root />
      <ToastContainer />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
