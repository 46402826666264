import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";

const Services = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Service - philipDiagnostic.com</title>
        <link rel="canonical" href="/services" />
      </Helmet>
      <Header />
      <div className="container-fluid service-header pt-3">
        <div className="row">
          <div className="col-md-4 offset-md-2 pt-5 service-blur">
            <h1 className="service-title">Our Services</h1>
            <div className="service-subTitle">
              Welcome to Philips Diagnostic Centre, your trusted partner in
              health and wellness, located in the heart of Akure, Ondo State,
              Nigeria. At Philips Diagnostic Centre, we are dedicated to
              providing top-notch Diagnostic services with a focus on accuracy,
              efficiency, and patient care
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ backgroundColor: "#E9F9FF", padding: "5% 5% 0 5%" }}
        className="service-main container-fluid"
      >
        <div className="row">
          <div className="col-md-4 offset-md-2 service-container shadow mb-5 pt-4 ps-4 pe-3 bg-white">
            <img
              alt=""
              src="images/services/imaging.png"
              className="service-img"
            />
            <div className="service-container-title mt-2 mb-2">
              {" "}
              Medical Imaging
            </div>
            <div>
              Our advanced medical imaging services bring your health into
              focus, providing precise insights for informed decisions and a
              pathway to a healthier tomorrow.
            </div>
            <div className="mt-5">
              <Link className="link" to="/imaging">
                View details <FaArrowRight className="ms-2" />
              </Link>{" "}
            </div>
          </div>

          <div className="col-md-4 service-container shadow ms-md-4 mb-5 pt-4 ps-4 pe-3 bg-white">
            <img
              alt=""
              src="images/services/pathology.png"
              className="service-img"
            />
            <div className="service-container-title mt-2 mb-2"> Pathology</div>
            <div>
              Through meticulous analysis of tissues and fluids, our pathology
              services provide crucial insights, guiding accurate diagnoses and
              personalized treatment plans
            </div>
            <div className="mt-5">
              <Link className="link" to="/pathology">
                View details <FaArrowRight className="ms-2" />
              </Link>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 offset-md-2 service-container shadow mb-5 pt-4 ps-4 pe-3 bg-white">
            <img
              alt=""
              src="images/services/drugs.png"
              className="service-img"
            />
            <div className="service-container-title mt-2 mb-2">
              {" "}
              Drug and Alcohol
            </div>
            <div>
              At Philips Diagnostic Center, we provide comprehensive testing
              services for drug and alcohol screenings. Our state-of-the-art
              facilities and expert team ensure accurate and confidential
              results, promoting a safer and healthier community
            </div>
            <div className="mt-5 mb-3">
              <Link className="link" to="/drug">
                View details <FaArrowRight className="ms-2" />
              </Link>{" "}
            </div>
          </div>

          <div className="col-md-4 service-container shadow ms-md-4 mb-5 pt-4 ps-4 pe-3 bg-white">
            <img
              alt=""
              src="images/services/calcare.png"
              className="service-img"
            />
            <div className="service-container-title mt-2 mb-2">
              {" "}
              Cardiac Care
            </div>
            <div>
              Ensuring Heart Health, One Beat at a Time. Our Cardiac Care
              Services combine cutting-edge technology with compassionate
              expertise, providing comprehensive Diagnostic and personalized
              solutions for your cardiovascular well-being
            </div>
            <div className="mt-5 mb-3">
              <Link className="link" to="/care">
                View details <FaArrowRight className="ms-2" />
              </Link>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 offset-md-2 service-container shadow mb-5 pt-4 ps-4 pe-3 bg-white">
            <img
              alt=""
              src="images/services/dialysis.png"
              className="service-img"
            />
            <div className="service-container-title mt-2 mb-2"> Dialysis</div>
            <div>
              Empowering Lives, One Dialysis Session at a Time: Providing
              Compassionate and Comprehensive Dialysis Care for Optimal
              Well-being.
            </div>
            <div className="mt-5">
              <Link className="link" to="/dialysis">
                View details <FaArrowRight className="ms-2" />
              </Link>{" "}
            </div>
          </div>

          <div className="col-md-4 service-container shadow ms-md-4 mb-5 pt-4 ps-4 pe-3 bg-white">
            <img
              alt=""
              src="images/services/ambulance.png"
              className="service-img"
            />
            <div className="service-container-title mt-2 mb-2">
              {" "}
              Ambulance Service
            </div>
            <div>
              Our Ambulance Service is dedicated to providing immediate, expert
              medical assistance when it matters most. Count on us for rapid,
              reliable transportation and professional, compassionate care
              during critical moments
            </div>
            <div className="mt-5">
              <Link className="link" to="/ambulance">
                View details <FaArrowRight className="ms-2" />
              </Link>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 offset-md-2 service-container shadow mb-5 pt-4 ps-4 bg-white">
            <img
              alt=""
              src="images/services/dialysis.png"
              className="service-img"
            />
            <div className="service-container-title mt-2 mb-2">
              {" "}
              DNA Testing
            </div>
            <div>
              Explore the power of DNA testing at our Diagnostic center. Uncover
              personalized insights into your health, ancestry, and potential
              risks. Your genetic code holds the key to a proactive and informed
              approach to well-being
            </div>
            <div className="mt-5">
              <Link className="link" to="/dna-testing">
                View details <FaArrowRight className="ms-2" />
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid service-footer">
        <div className="row">
          <div className="col-md-6 offset-md-3 align-item-center">
            <center>
              <img
                alt="DNA testing"
                src="images/heart-beat.png"
                className="img-fluid"
                style={{ height: 68, width: 73 }}
              />{" "}
            </center>
            <h4 className="text-white text-center mt-3">
              Your Health, Your Schedule - Book an Appointment Today!{" "}
              <Dot color={"#D21D22"} />
            </h4>
            <div className="text-white text-center mb-5">
              Take control of your well-being with Philips Diagnostic. Our
              expert team is ready to provide you with personalized care and
              precise health assessments. Book your appointment now to embark on
              a journey to better health.
            </div>
            <center>
              <a
                className="btn btn-read"
                type="submit"
                href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
              >
                Book Appointment
              </a>
            </center>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
