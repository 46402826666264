import React from "react";
import check from "../../Assets/check.svg";

const IndividualPlan = () => {
  return (
    <div>
      <div className="flex justify-content-start mt-5 planContainer">
        <div className="testCont p-3">
          <h4 className="planName">Silver health plan</h4>

          <p>17 Tests</p>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Pre evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Post evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Physical (weight, height)</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Blood sugar</h5>
          </div>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Stool</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine Analysis </h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine acid</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Chest X Ray</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Full Blood count</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hiv screening</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Eye checkup</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Lipid profile</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Renal Function Test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hepatitis</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ECG</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Liver Function test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ESR</h5>
          </div>
        </div>

        <div className="testCont p-3">
          <h4 className="planName">Gold health plan</h4>
          <p>19 Tests</p>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Pre evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Post evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Physical (weight, height)</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Blood sugar</h5>
          </div>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Stool</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine Analysis </h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine acid</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Chest X Ray</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Full Blood count</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hiv screening</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Eye checkup</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Lipid profile</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Renal Function Test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hepatitis</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ECG</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Liver Function test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ESR</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Abdominopelvic Ultrasound test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Thyroid Function test</h5>
          </div>
        </div>

        <div className="testCont p-3">
          <h4 className="planName">Diamond health plan</h4>
          <p>21 Tests</p>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Pre evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Post evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Physical (weight, height)</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Blood sugar</h5>
          </div>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Stool</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine Analysis </h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine acid</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Chest X Ray</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Full Blood count</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hiv screening</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Eye checkup</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Lipid profile</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Renal Function Test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hepatitis</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ECG</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Liver Function test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ESR</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Abdominopelvic Ultrasound test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Thyroid Function test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>PSA/PAP Smear</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Spirometry</h5>
          </div>
        </div>
      </div>

      <div className="flex justify-content-start mt-5 planContainer">
        <div className="testCont p-3">
          <h4 className="planName">Platinum health plan</h4>
          <p>25 Tests</p>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Pre evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Post evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Physical (weight, height)</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Blood sugar</h5>
          </div>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Stool</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine Analysis </h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine acid</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Chest X Ray</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Full Blood count</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hiv screening</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Eye checkup</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Lipid profile</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Renal Function Test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hepatitis</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ECG</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Liver Function test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ESR</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Abdominopelvic Ultrasound test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Thyroid Function test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>PSA/PAP Smear</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Stress Egg</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Spirometry</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Echocaridogram</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>CEA</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hba1C</h5>
          </div>
        </div>

        <div className="testCont p-3">
          <h4 className="planName">Executive health plan</h4>
          <p>26 Tests</p>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Pre evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Post evaluation</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Physical (weight, height)</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Blood sugar</h5>
          </div>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Stool</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine Analysis </h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Urine acid</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Chest X Ray</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Full Blood count</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hiv screening</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Eye checkup</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Lipid profile</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Renal Function Test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hepatitis</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ECG</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Liver Function test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>ESR</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Abdominopelvic Ultrasound test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Thyroid Function test</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>PSA/PAP Smear</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Stress Egg</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Spirometry</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Echocaridogram</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>CEA</h5>
          </div>

          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>Hba1C</h5>
          </div>
          <div className="flex justify-content-start testTypeCont">
            <img width="30" className="mr-3" alt="" src={check} />
            <h5>CT Scan (One Study)</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualPlan;
