import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="carousel-item active"
          data-bs-interval="10000"
        >
          <div className="testimony-item row">
            <div className="col-md-3 img-div">
              <img
                alt=""
                src="images/person2.jpg"
                className="img-fluid d-none d-md-block"
                style={{ width: "320px" }}
              />
            </div>
            <div className="col-md-5 pt-50 text-start d-none d-md-block">
              <p>
                "Outstanding service at Philips Diagnostic Center! Efficient,
                professional, and caring staff. Results delivered promptly with
                accuracy. Highly recommend! "
              </p>
              <br />
              <div className="testifier"> Chirstopher Ibekwe </div>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="carousel-item active"
          data-bs-interval="10000"
        >
          <div className="testimony-item row">
            <div className="col-md-3 img-div">
              <img
                alt=""
                src="images/person5.jpg"
                className="img-fluid d-none d-md-block"
                style={{ width: "320px" }}
              />
            </div>
            <div className="col-md-5 pt-50 text-start d-none d-md-block">
              <p>
                "⁠Exceptional service at Philips Diagnostic Center! From booking
                an appointment to receiving results, the entire process was
                seamless. The staff was professional, courteous, and made me
                feel comfortable throughout my visit. I highly recommend for
                top-notch Diagnostic services"
              </p>
              <br />
              <div className="testifier"> Mercy Ajala </div>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="carousel-item active"
          data-bs-interval="10000"
        >
          <div className="testimony-item row">
            <div className="col-md-3 img-div">
              <img
                alt=""
                src="images/person3.jpg"
                className="img-fluid d-none d-md-block"
                style={{ width: "320px" }}
              />
            </div>
            <div className="col-md-5 pt-50 text-start d-none d-md-block">
              <p>
                "⁠I sincerely enjoyed my time at Philips Diagnostic Center. The
                environment is neat and their equipment are state of the art. I
                will be referring my family and friends to use their services."
              </p>
              <br />
              <div className="testifier"> Chinenye Joy </div>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="carousel-item active"
          data-bs-interval="10000"
        >
          <div className="testimony-item row">
            <div className="col-md-3 img-div">
              <img
                alt=""
                src="images/person1.jpg"
                className="img-fluid d-none d-md-block"
                style={{ width: "320px" }}
              />
            </div>
            <div className="col-md-5 pt-50 text-start d-none d-md-block">
              <p>
                "I love the ambience and their staff are highly trained
                professionals. I almost forgot that I was still in Nigeria. They
                really took healthcare in Akure to the next level. Well done!"
              </p>
              <br />
              <div className="testifier"> Micheal Adeoye </div>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="carousel-item active"
          data-bs-interval="10000"
        >
          <div className="testimony-item row">
            <div className="col-md-3 img-div">
              <img
                alt=""
                src="images/person4.jpg"
                className="img-fluid d-none d-md-block"
                style={{ width: "320px" }}
              />
            </div>
            <div className="col-md-5 pt-50 text-start d-none d-md-block">
              <p>
                "⁠After my last visit to Philips Dignostic Center, I made up my
                mind that I and my family will not be using any other Diagnostic
                center other than this. I’m very impressed with their services."
              </p>
              <br />
              <div className="testifier"> Samantha Margabu </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
