import React from "react";
import { Header, Footer } from "../../custom";
import { Helmet } from "react-helmet";
import gallery1 from "../../Assets/gallery1.png";
import gallery2 from "../../Assets/gallery2.png";
import gallery3 from "../../Assets/gallery3.png";
import gallery4 from "../../Assets/gallery4.png";
import gallery5 from "../../Assets/gallery5.png";
import gallery6 from "../../Assets/gallery6.png";
import gallery7 from "../../Assets/gallery7.png";
import gallery8 from "../../Assets/gallery8.png";
import gallery9 from "../../Assets/gallery9.png";
import gallery10 from "../../Assets/gallery10.png";
import gallery11 from "../../Assets/gallery11.png";
import gallery12 from "../../Assets/gallery12.png";
import gallery13 from "../../Assets/gallery13.png";
import gallery14 from "../../Assets/gallery14.png";
import gallery15 from "../../Assets/gallery15.png";
import gallery16 from "../../Assets/gallery16.png";

const Gallery = () => {
  const images = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,
    gallery11,
    gallery12,
    gallery13,
    gallery14,
    gallery15,
    gallery16,
  ];
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gallery-philipsDiagnostic.com</title>
        <link rel="canonical" href="/gallery" />
      </Helmet>
      <Header />
      <div
        style={{ padding: "0 5%" }}
        className="flex justify-content-center align-items-center flex-wrap m-3"
      >
        {images.map((image, index) => (
          <img
            className="m-2"
            width="296px"
            height="207px"
            key={index}
            src={image}
            alt=""
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
