import React from "react";
import Dot from "../dot";
// import member1 from "../../Assets/member1.png";
// import member2 from "../../Assets/member2.png";
// import member3 from "../../Assets/member3.png";
// import member4 from "../../Assets/member4.png";
// import member5 from "../../Assets/member5.png";
// import { Link } from "react-router-dom";
import ControlledCarousel from "./Words";

const Testimony = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 flex flex-column justify-content-center w-100 mb-5 mt-5">
            <h4 className="text-danger text-center">
              What Our Patients Say <Dot color={"#D21D22"} />{" "}
            </h4>
            <div
              style={{ margin: "0 20%" }}
              className="text-center lh-28 mt-20 mb-20 ps-sm-4 pe-sm-4"
            >
              The stories of our patients speak to the commitment we have to
              your health and well-being. Read the testimonials below to learn
              about their experiences with Precision Diagnostic.
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselExampleLight"
        className="carousel carousel-light slide mb-50"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <ControlledCarousel />
        </div>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleLight"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleLight"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      {/* <div className="container bg-white p-3 mb-5">
        <div className="flex membersCont">
          <div style={{ margin: "0" }} className="w-100">
            <h4>
              Meet our Board Members <Dot color={"#D21D22"} />
            </h4>
            <p style={{ width: "90%", color: "#18191B" }}>
              Meet Our Distinguished Medical Advisory Board: Experts Guiding Our
              Healthcare Mission
            </p>
          </div>
          <div className=" membersCont flex justify-content-end w-100% ml-5 pl-5">
            <div
              style={{
                backgroundImage: `url(${member1})`,
                marginRight: "3%",
                width: "212px",
                height: "234px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="membersCont1"
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p color="#D21D22">CEO</p>
              </div>
            </div>

            <div
              className="membersCont1"
              style={{
                backgroundImage: `url(${member2})`,
                width: "212px",
                height: "234px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p>CEO</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex mt-3">
          <div className="forMore">
            <Link to="/members">
              <h5>See all members</h5>
            </Link>
          </div>
          <div
            className="remMembers flex justify-content-end"
            style={{ width: "80%" }}
          >
            <div
              style={{
                marginRight: "1%",
                width: "212px",
                height: "234px",
                backgroundImage: `url(${member3})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p color="#D21D22">CEO</p>
              </div>
            </div>

            <div
              style={{
                marginRight: "1%",
                width: "212px",
                height: "234px",
                backgroundImage: `url(${member4})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p>CEO</p>
              </div>
            </div>

            <div
              style={{
                width: "212px",
                height: "234px",
                backgroundImage: `url(${member5})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p>CEO</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Testimony;
