import React from "react";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Slider = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div
      id="carouselExampleDark"
      className="carousel carousel-light slide"
      data-bs-ride="carousel"
    >
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item data-bs-interval="5000">
          {" "}
          {/* Changed interval to 3000ms */}
          <img
            src="images/slides/slide1.png"
            className="d-block w-100 home-slider"
            alt="..."
          />
          <div className="carousel-caption col-md-4 d-none d-md-block">
            <h2 className="text-wrap text-start">
              Empowering Your Health Journey with Philips Diagnostic Center
            </h2>
            <p className="paragraph text-start mt-4">
              At Philips Diagnostic Center, we're dedicated to providing you
              with precise health insights, cutting-edge Diagnostic, and
              personalized care for a healthier future.
            </p>
            <p /> <br />
            <div className="button-container col-md-7">
              <a
                className="btn btn-slider"
                type="submit"
                href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
              >
                Book Appointment
              </a>
            </div>
          </div>
          {/* mobile view */}
          <div className="carousel-caption col-sm-12 d-md-none d-lg-none">
            <h4 className=" text-start">
              Empowering Your Health Journey with Philips Diagnostic
            </h4>{" "}
            <br />
            <p className="paragraph text-start size14">
              At Philips Diagnostic, we're dedicated to providing you with
              precise health insights, cutting-edge Diagnostic, and personalized
              care for a healthier future.
            </p>
            <p /> <br />
            <div className="button-container col-sm-12">
              <a
                className="btn btn-slider"
                type="submit"
                href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
              >
                Book Appointment
              </a>
            </div>
          </div>
          {/*end of mobile view */}
        </Carousel.Item>
        <Carousel.Item data-bs-interval="5000">
          <img
            src="images/slides/slide2.png"
            className="d-block w-100 home-slider"
            alt="..."
          />
          <div className="caption col-md-4 d-none d-md-block">
            <h2 className="text-wrap text-start">
              Happiness Starts with Good Health at Philips Diagnostic
            </h2>
            <p className="paragraph text-start">
              Join us in a celebration of well-being! Our team is dedicated to
              making your health experience joyful, ensuring you smile at every
              step
            </p>
            <p /> <br />
            <div className="button-container col-md-7">
              <button
                className="btn btn-slider"
                type="submit"
                target="_blank"
                href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
              >
                Book Appointment
              </button>
            </div>
          </div>
          {/* mobile view */}
          <div className="carousel-caption col-sm-12 d-md-none d-lg-none">
            <h4 className=" text-start">
              Happiness Starts with Good Health at Philips Diagnostic
            </h4>{" "}
            <br />
            <p className="paragraph text-start size14">
              Join us in a celebration of well-being! Our team is dedicated to
              making your health experience joyful, ensuring you smile at every
              step
            </p>
            <p /> <br />
            <div className="button-container col-sm-12">
              <a
                className="btn btn-slider"
                type="submit"
                href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
              >
                Book Appointment
              </a>
            </div>
          </div>
          {/*end of mobile view */}
        </Carousel.Item>
        <Carousel.Item data-bs-interval="5000">
          <img
            src="images/slides/slide3.png"
            className="d-block w-100 home-slider"
            alt="..."
          />
          <div className="caption offset-md-1 col-md-4 d-none d-md-block">
            <h2 className="text-wrap text-start">
              Embrace a Healthier You with Philips Diagnostic
            </h2>
            <p className="paragraph text-start">
              Discover a friendlier approach to healthcare. At our center, we
              blend expertise with warmth, ensuring your health journey is both
              precise and personable.
            </p>
            <p /> <br />
            <div className="button-container col-md-7">
              <a
                className="btn btn-slider"
                type="submit"
                href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
              >
                Book Appointment
              </a>
            </div>
          </div>
          {/* mobile view */}
          <div className="carousel-caption col-sm-12 d-md-none d-lg-none">
            <h4 className=" text-start">
              Embrace a Healthier You with Philips Diagnostic
            </h4>{" "}
            <br />
            <p className="paragraph text-start size14">
              Discover a friendlier approach to healthcare. At our center, we
              blend expertise with warmth, ensuring your health journey is both
              precise and personable.
            </p>
            <p /> <br />
            <div className="button-container col-sm-12">
              <a
                className="btn btn-slider"
                type="submit"
                href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
              >
                Book Appointment
              </a>
            </div>
          </div>
          {/*end of mobile view */}
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Slider;
