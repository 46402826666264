import React from "react";
import { Header, Footer } from "../../custom";
import { Helmet } from "react-helmet";
import member3 from "../../Assets/member3.png";
import member4 from "../../Assets/member4.png";
import member5 from "../../Assets/member5.png";

const Members = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Members-philipsdiagostics.com</title>
        <link rel="canonical" href="/members" />
      </Helmet>
      <Header />
      <div className="container mt-5">
        <div className="text-center ">
          <h2>Meet our Board Members</h2>
          <p>
            Meet Our Distinguished Medical Advisory Board: Experts Guiding Our
            Healthcare Mission
          </p>
        </div>

        <div className="flex flex-column justify-content-center align-items-center  mt-5">
          <div
            className="membersCont justify-content-center flex mb-3"
            style={{ width: "90%" }}
          >
            <div
              style={{
                marginRight: "1%",
                width: "312px",
                height: "344px",
                backgroundImage: `url(${member3})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="membersCont1"
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p color="#D21D22">CEO</p>
              </div>
            </div>

            <div
              style={{
                marginRight: "1%",
                width: "312px",
                height: "344px",
                backgroundImage: `url(${member4})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="membersCont1"
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p>CEO</p>
              </div>
            </div>

            <div
              style={{
                width: "312px",
                height: "344px",
                backgroundImage: `url(${member5})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="membersCont1"
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p>CEO</p>
              </div>
            </div>
          </div>

          <div
            className="membersCont flex justify-content-center"
            style={{ width: "90%" }}
          >
            <div
              style={{
                marginRight: "1%",
                width: "312px",
                height: "344px",
                backgroundImage: `url(${member3})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="membersCont1"
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p color="#D21D22">CEO</p>
              </div>
            </div>

            <div
              style={{
                marginRight: "1%",
                width: "312px",
                height: "344px",
                backgroundImage: `url(${member4})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="membersCont1"
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p>CEO</p>
              </div>
            </div>

            <div
              style={{
                width: "312px",
                height: "344px",
                backgroundImage: `url(${member5})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              className="membersCont1"
            >
              <div className="text-white memberPos">
                <p>Daniel Daniel John</p>
                <p>CEO</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-50 mb-50" style={{ height: 50 }}></div>
      <Footer />
    </div>
  );
};

export default Members;
