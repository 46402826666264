import React from "react";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";

const Dialysis = () => {
  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dialysis-philipsdiagostics.com</title>
        <link rel="canonical" href="/dialysis" />
      </Helmet>
      <Header />
      <div className="container-fluid dialysis-header pt-3">
        <div className="row">
          <div className="col-md-4 offset-md-2 pt-5 mt-5 dialysis-blur">
            <h1 className="service-title">Dialysis</h1>
            <div className="service-subTitle">
              Empowering Lives, One Dialysis Session at a Time: Providing
              Compassionate and Comprehensive Dialysis Care for Optimal
              Well-being.
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselEp"
        className="carousel carousel-light slide mt-50 mb-50"
        data-bs-ride="carousel"
        data-bs-wrap="true"
      >
        <div className="carousel-inner">
          {/* slider item 1 */}
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="container">
              <div className="row ">
                <div className="col-md-4 offset-md-1">
                  <h6 className="mt-20 d-md-none d-lg-none text-dark">
                    Dialysis <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <img
                    alt=""
                    src="images/dialysis-patient.png"
                    className="img-fluid mt-3"
                  />
                </div>
                <div className="col-md-6  ps-md-5">
                  <h6 className="mt-20 d-none d-md-block text-dark">
                    Dialysis <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <div className="text-start mt-2 text-dark mt-3-sm lh-28">
                    Philips Diagnostic Center provides advanced and
                    comprehensive hemodialysis services, offering a lifeline to
                    individuals with renal conditions. Our facility is fully
                    equipped with cutting-edge technology and staffed by a
                    skilled team of healthcare professionals dedicated to
                    delivering high-quality care. Hemodialysis involves the
                    meticulous removal of waste products and excess fluids from
                    the blood, serving as a vital treatment for patients with
                    impaired kidney function. Our dialysis unit ensures a
                    comfortable and sterile environment for each session,
                    prioritizing patient safety and well-being. With a
                    commitment to excellence, we personalize hemodialysis
                    treatment plans to meet the unique needs of each patient.
                    Whether for acute or chronic renal conditions, our focus is
                    on optimizing the quality of life for individuals undergoing
                    hemodialysis. Philips Diagnostic Center is dedicated to
                    providing compassionate and effective hemodialysis services,
                    contributing to the overall health and well-being of our
                    patients.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> */}
      </div>
      <div className="mt-50 mb-50" style={{ height: 50 }}></div>
      <Footer />
    </div>
  );
};

export default Dialysis;
