import React from "react";
import { FaEnvelope, FaLocationDot, FaPhone } from "react-icons/fa6";
import axios from "axios";
import { toast } from "react-toastify";
import { Header, Footer } from "../../custom";
import { useMergedState } from "../../lib/util";
import { Helmet } from "react-helmet";
import linkedIn from "../../Assets/linkedIn.svg";
const Contact = () => {
  const [state, setState] = useMergedState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("this.state", state);
    let errors = {};
    if (state.email.length === "") {
      errors.email = "Email is needed";
    } else if (state.fname === "") {
      errors.fname = "Please enter your First Name";
    } else if (state.lname === "") {
      errors.lname = "Please enter your Last Name";
    } else if (state.phone === "") {
      errors.phone = "Please enter your Phone Number";
    } else if (state.subject === "") {
      errors.subject = "Please select the subject you want to send";
    } else if (state.message === "") {
      errors.message = "Please enter the Message you want to send";
    } else {
      const body = {
        first_name: state.fname,
        last_name: state.lname,
        subject: state.subject,
        phone_number: state.phone,
        message: state.message,
        email: state.email,
      };

      try {
        const { data } = await axios.post(
          "https://portal.philipsDiagnosticcenter.com/guest/message",
          body,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        console.log(data); 
        toast.success("We will get back to you shortly.", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setState({
          fname: "",
          lname: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      } catch (error) {
        console.log("error", error);
         toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact - philipsDiagnostic.com</title>
        <link rel="canonical" href="/contact" />
      </Helmet>
      <Header />
      <div className="container-fluid contact-header pt-3">
        <div className="row">
          <div className="col-md-5 offset-md-3 pt-5 contact-blur">
            <h1 className="service-title text-center">Contact Us</h1>
            <div className="service-subTitle text-center">
              Have questions, need assistance, or ready to schedule an
              appointment? Reach out to our dedicated team. We're here to
              listen, guide, and ensure your experience with us is seamless and
              supportive. Your health journey starts with a conversation.
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 side ps-md-5 pt-5">
            <div className="mt-md-5 ms-md-5 ps-md-5">
              <h4 className="text-white mb-3">Philips Diagnostic Center</h4>
              <div className="col-md-8 text-white lh-50 ps-sm-2 pe-sm-2">
                Welcome to Philips Diagnostic Centre, your trusted partner in
                health and wellness, located in the heart of Akure, Ondo State,
                Nigeria. At Philips Diagnostic Centre, we are dedicated to
                providing top-notch Diagnostic services with a focus on
                accuracy, efficiency, and patient care.
              </div>
              <a
                href="https://maps.app.goo.gl/H3QcixfgybVGWNVh9"
                className="text-white mt-4 d-block mb-3 text-decoration-none"
              >
                <FaLocationDot color="red" />{" "}
                <span style={{fontSize: "14px"}} className="ms-2">
                  {" "}
                  No 5Ob, Along Idanre Road, Oke Aro Street, Akure, Ondo State.
                  Nigeria.
                </span>
              </a>

              <a
                href="mailto:info@philipsdiagnosticcenter.com"
                className="text-white ps-1 d-block mb-3 text-decoration-none"
              >
                <FaEnvelope />{" "}
                <span style={{fontSize: "14px"}} className="ms-2">
                  Email Address: info@philipsdiagnosticcenter.com
                </span>
              </a>

              <a
                href="tel:+2349135002227"
                className="text-white ps-1 d-block mb-3 text-decoration-none"
              >
                <FaPhone />{" "}
                <span style={{fontSize: "14px"}} className="ms-2"> +2349135002227, +2349135002226</span>
              </a>

              <a
                href="https://www.facebook.com/profile.php?id=61554965094535"
                className="text-white d-block mb-3 text-decoration-none"
                style={{fontSize: "14px"}}
              >
                {" "}
                <img
                  src="images/icon/facebook.png"
                  className="img-fluid me-2"
                  width="30"
                  height="30"
                  alt=""
                />{" "}
                Philips Diagnostic Center
              </a>
              <a
                href="https://www.instagram.com/philipsDiagnosticcenter/"
                className="text-white d-block mb-3 text-decoration-none"
                style={{fontSize: "14px"}}
              >
                <img
                  src="images/icon/instagram.png"
                  className="img-fluid me-2"
                  width="30"
                  height="30"
                  alt=""
                />
                @philipsDiagnosticcenter
              </a>
              <a
                href="https://twitter.com/PhilipsDXCenter"
                className="text-white mb-3 text-decoration-none"
                style={{fontSize: "14px"}}
              >
                {" "}
                <img
                  src="images/icon/x.png"
                  className="img-fluid me-2"
                  width="30"
                  height="30"
                  alt=""
                />{" "}
                @PhilipsDXCenter
              </a>

              <a
                style={{ textDecoration: "none", fontSize: "14px" }}
                href="https://www.linkedin.com/company/philips-diagnostic-center/"
                className="text-white d-block mt-3"
                
              >
                <img
                  alt=""
                  src={linkedIn}
                  className="img-fluid me-2"
                  width="30"
                  height="30"
                />
                <span>@philipsDiagnosticcenter</span>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="https://wa.me/2349135002226"
                className="text-white d-block mt-3"
              >
                {" "}
                <img
                  src="images/icon/whatsapp.svg"
                  className="img-fluid me-2"
                  width="30"
                  height="30"
                  alt=""
                />
                +2349135002226
              </a>

              <p>
                <a
                  style={{ color: "#017DB9" }}
                  className="btn btn-white mt-3"
                  href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
                >
                  Book Appointment
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-4 pt-5 ps-md-4">
            <h4 className="mb-4 mt-5">Send us a message</h4>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label for="fname" className="form-label">
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={state.fname}
                  required
                  className="form-control"
                  id="fname"
                  aria-describedby="emailHelp"
                  name="fname"
                  onChange={(fname) => setState({ fname: fname.target.value })}
                />
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div className="mb-3">
                <label for="lname" className="form-label">
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={state.lname}
                  required
                  className="form-control"
                  id="lname"
                  name="lname"
                  onChange={(lname) => setState({ lname: lname.target.value })}
                />
              </div>

              <div className="mb-3">
                <label for="email" className="form-label">
                  Email address <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  required
                  value={state.email}
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  name="email"
                  onChange={(email) => setState({ email: email.target.value })}
                />
                <div id="emailHelp" className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div className="mb-3">
                <label for="phone" className="form-label">
                  Phone Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={state.phone}
                  required
                  className="form-control"
                  id="phone"
                  name="phone"
                  onChange={(phone) => setState({ phone: phone.target.value })}
                />
              </div>

              <div className="mb-3">
                <label for="subject" className="form-label">
                  Subject <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  required
                  id="subject"
                  aria-label="Default select example"
                  name="subject"
                  onChange={(subject) =>
                    setState({ subject: subject.target.value })
                  }
                >
                  <option selected>Choose subject</option>
                  <option value="feedback">Feedback</option>
                  <option value="enquiry">Enquiry</option>
                  <option value="complaint">Complaint</option>
                  <option value="report follow up">Report follow up</option>
                </select>
              </div>

              <div className="mb-3 mt-4">
                <label for="sub" className="form-label">
                  Subject <span className="text-danger">*</span>
                </label>
                <textarea
                  required
                  className="form-control"
                  id="sub"
                  placeholder="Type your message"
                  style={{ height: 264 }}
                  name="message"
                  value={state.message}
                  onChange={(message) =>
                    setState({ message: message.target.value })
                  }
                />
              </div>
              <button type="submit" className="btn btn-read mb-4">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
