import React from "react";
import { Link } from "react-router-dom";
import {
  Header,
  Slider,
  Container,
  Dot,
  Testimony,
  Footer,
} from "../../custom";
import { Helmet } from "react-helmet";

const Home = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - philipsDiagnostic.com</title>
        <link rel="canonical" href="/" />
      </Helmet>
      <Header />
      <Slider />
      <div className="container mainBg mt-50 mb-50">
        <div className="row row-cols-1 row-cols-md-4  custom-gutter-margin">
          <Container
            color={"#E4FAF2"}
            title="Total Customers Served"
            value="100"
            fontColor="#0A704B"
            img="images/icon/man.png"
          />
          <Container
            color={"#E4E8FA"}
            title="Years of Excellence"
            value="1+"
            fontColor="#061B77"
            img="images/icon/badge.png"
          />
          <Container
            color={"#D2EEF8"}
            title="Patient Satisfaction"
            value="98%"
            fontColor="#017DB9"
            img="images/icon/review.png"
          />
          <Container
            color={"#E8FDFA"}
            title="Total Partnership "
            value="2"
            fontColor="#18CBB1"
            img="images/icon/handshake.png"
          />
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img
              alt=""
              src="images/hallway.jpg"
              className="img-fluid hallway"
            />
          </div>
          <div className="col-md-8">
            <h6 className=" mt-20 ps-sm-2">
              Welcome to <Dot color={"#D21D22"} />{" "}
            </h6>
            <h4 className=" text-danger ps-sm-2">Philips Diagnostic Center </h4>
            <div className="justify lh-30 col-sm-11  ps-sm-2 pe-sm-2">
              Welcome to Philips Diagnostic Centre, Akure, nestled in the heart
              of Ondo State, Nigeria. At our state-of-the-art facility, we
              prioritize your health and well-being through cutting-edge
              Diagnostic services. Founded with a commitment to excellence, we
              bring together a team of skilled healthcare professionals and
              advanced technology to provide accurate and timely Diagnostic
              solutions. Our center is equipped with the latest Diagnostic
              equipment, ensuring precision in medical imaging and Diagnostic.
              As a trusted healthcare partner in the community, we strive to
              create a warm and welcoming environment for our patients. Our
              dedicated team is committed to delivering compassionate care while
              upholding the highest standards of medical excellence.
            </div>
            <p className="">
              <Link to="/about" className="btn btn-read" type="submit">
                Read More...
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-50">
        <div className="row">
          <div className="col-md-6 service-tab">
            <h4>
              Our Services <Dot />
            </h4>
            <div>
              At Philips Diagnostic Center, our array of services is designed to
              address your unique health needs with precision and care. Explore
              our offerings below to discover how we can partner with you on
              your health journey.
            </div>
            <p>
              <Link to="/services">
                <button className="btn btn-read" type="submit">
                  View our services
                </button>
              </Link>
            </p>
          </div>
          <div className="col-md-6 service-tab-right d-none d-md-block"></div>
        </div>
      </div>

      <div class="bg-white appointmentCont p-3">
        <div class="row pt-5 pb-5">
          <div class="col-md-5 offset-md-1">
            <img
              alt=""
              src="images/heart-beat.png"
              style={{ height: 63, width: 70 }}
              className="img-fluid d-md-none d-lg-none"
            />
            <h4 className="d-md-none d-lg-none">
              Your Health, Your Schedule - Book an Appointment Today!{" "}
              <Dot color={"#D21D22"} />{" "}
            </h4>
            <img
              src="images/calendar.png"
              alt="CenteredImage"
              className="img-fluid rock "
            />
          </div>

          <div className="col-md-6">
            <img
              alt=""
              src="images/heart-beat.png"
              style={{ height: 63, width: 70 }}
              className="img-fluid d-none d-md-block"
            />
            <h4 style={{ width: 454 }} className=" d-none d-md-block">
              Your Health, Your Schedule - Book an Appointment Today!{" "}
              <Dot color={"#D21D22"} />{" "}
            </h4>
            <div className="health">
              Take control of your well-being with Philips Diagnostic. Our
              expert team is ready to provide you with personalized care and
              precise health assessments. Book your appointment now to embark on
              a journey to better health.
            </div>
            <p className="mt-20">
              <a
                className="btn btn-read"
                type="submit"
                href="https://portal.philipsDiagnosticcenter.com/guest/new_appointment"
              >
                Book Appointment
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className=" mb-50">
        <div className="row mission">
          <div className="col-md-3 column">
            <img
              alt=""
              src="images/icon/mission.png"
              className="img-fluid mission-icon"
            />
            <span className="mission-name">Mission</span>
            <div className="fs-13">
              At Philips Diagnostic Centre, our mission is to empower
              individuals with precise and timely Diagnostic insights, fostering
              a healthier community in Akure, Ondo State, Nigeria. Committed to
              excellence, we leverage state-of-the-art Diagnostic technology and
              a dedicated team of healthcare professionals to provide accurate
              and compassionate Diagnostic services.
            </div>
          </div>

          <div className="col-md-3 ml-40 column">
            <img
              alt=""
              src="images/icon/vision.png"
              className="img-fluid mission-icon"
            />
            <span className="mission-name2">Vision</span>
            <div className="fs-13">
              Philips Diagnostic Center envisions a future where health meets
              innovation, and every individual in Akure, Ondo State, Nigeria
              experiences superior Diagnostic care. As a leading hub of
              excellence, our vision is to redefine healthcare through
              cutting-edge technology, personalized patient experiences, and a
              commitment to advancing medical Diagnostic.
            </div>
          </div>

          <div className="col-md-3 ml-40 column">
            <div style={{ widows: "100%" }}>
              <h4 className="text-danger pe-sm-2">Our Core Values</h4>
              <div className="pe-sm-2">
                <p>Our Guiding Lights:</p>
                <ul className="list-grp">
                  <li className="list-grp-item border-0"> Excellence</li>
                  <li className="list-grp-item border-0"> Integrity</li>
                  <li className="list-grp-item border-0"> Professionalism </li>
                </ul>
                <br />
                <p className="text-bold">
                  The Core Values Illuminating Our Healthcare Journey
                </p>
                <div style={{ width: "100%" }} className="btn-cont">
                  <Link style={{ width: "100%" }} to="/about#section">
                    <button className="btn-view">View all</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimony />
      <Footer />
    </div>
  );
};

export default Home;
