import React from "react";
// import { FaArrowRight } from "react-icons/fa6";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";

const Care = () => {
  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CardiacCare - philipsDiagnostic.com</title>
        <link rel="canonical" href="/cardiac-care" />
      </Helmet>
      <Header />
      <div className="container-fluid care-header pt-3">
        <div className="row">
          <div className="col-md-4 offset-md-2 pt-5 care-blur">
            <h1 className="service-title">Cardiac Care</h1>
            <div className="service-subTitle">
              Welcome to Philips Diagnostic Centre, your trusted partner in
              health and wellness, located in the heart of Akure, Ondo State,
              Nigeria. At Philips Diagnostic Centre, we are dedicated to
              providing top-notch Diagnostic services with a focus on accuracy,
              efficiency, and patient care
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselEp"
        className="carousel carousel-light slide mt-50 mb-50"
        data-bs-ride="carousel"
        data-bs-wrap="true"
      >
        <div className="carousel-inner">
          {/* slider item 1 */}
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="container">
              <div className="row ">
                <div className="col-md-4 offset-md-1">
                  <h6 className="mt-20 d-md-none d-lg-none text-dark">
                    Cardiac Care <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <img
                    alt=""
                    src="images/cardiac.png"
                    className="img-fluid mt-3"
                  />
                </div>
                <div className="col-md-6  ps-md-5">
                  <h6 className="mt-20 d-none d-md-block text-dark">
                    Cardiac Care <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <div className="text-start mt-2 text-dark pe-sm-2 mt-3-sm lh-28">
                    Cardiovascular issues continue to be a significant challenge
                    in Nigeria, persisting as the primary cause of many
                    unexpected fatalities. At Philips Diagnostic Center, our
                    dedicated team of cardiac experts is devoted to providing
                    exceptional care for your heart health. We provide an
                    extensive array of cardiology services, encompassing
                    non-invasive tests for early detection and intervention. Our
                    approach is personalized, ensuring not only a thorough focus
                    on the medical aspects but also a holistic consideration of
                    your overall well-being as an individual. Trust us for
                    world-class cardiac care that prioritizes your heart's
                    health and your overall quality of life. The comprehensive
                    scope of our Cardiac care services encompasses Stress ECG,
                    Resting ECG, Doppler tests, Echocardiogram, Cardiac risk
                    profile, Group/sport cardiac profile,
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> */}
      </div>
      <div className="mt-50 mb-50" style={{ height: 50 }}></div>
      <Footer />
    </div>
  );
};

export default Care;
