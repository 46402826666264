import React from "react";
import { Header, Footer, Dot } from "../../custom";
import { useMergedState } from "../../lib/util";
import { Helmet } from "react-helmet";

const FAQ = () => {
  const [state, setState] = useMergedState({
    a: false,
    b: false,
    c: false,
    d: false,
    e: false,
    f: false,
    g: false,
    i: false,
  });

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Faq-philipsDiagnostic.com</title>
        <link rel="canonical" href="/faq" />
      </Helmet>
      <Header />
      <div className="container-fluid faq-header pt-3">
        <div className="row">
          <div className="col-md-5 offset-md-3 pt-5 contact-blur">
            <h1 className="faq-title text-center">
              Frequently Asked Questions
            </h1>
            <div className="service-subTitle text-center mt-3">
              Discover answers to commonly asked questions about our services,
              appointments, and more. Your health journey begins with knowledge,
              and we're here to provide clarity every step of the way. Explore
              our FAQs to empower your understanding of our medical Diagnostic
              center.
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#E9F9FF" }} className="container-fluid">
        <div className="row faq-container">
          <div className="col-md-3 ps-md-5">
            <h6>
              FAQs <Dot color={"#D21D22"} />
            </h6>
            <h4>Common questions about Philips Diagnostic Center</h4>
          </div>
          <div className="col-md-8 offset-md-1">
            <section>
              <p className="d-flex flex-row justify-content-between col-md-8">
                <div className="collapse-title">
                  Is Philips Diagnostic Center a hospital?
                </div>
                <a
                  class="btn"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  onClick={() => setState({ a: !state.a })}
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <img
                    alt=""
                    src={
                      state.a === true ? "images/minus.png" : "images/plus.png"
                    }
                    className="img-fluid"
                    style={{ width: 24, height: 24 }}
                  />
                </a>
              </p>
              <div class="collapse" id="collapseExample">
                <div class="col-md-8 ">
                  No, Philips Diagnostic Center is a Diagnostic center. We offer
                  a wide variety of Diagnostic services that allow us to meet
                  the needs of our clients.
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom col-md-8 collapse-container"></div>
            </section>

            <section>
              <p className="d-flex flex-row justify-content-between col-md-8">
                <div className="collapse-title">
                  Where is Philips Diagnostic Center located?
                </div>
                <a
                  class="btn"
                  data-bs-toggle="collapse"
                  href="#collapse3"
                  role="button"
                  onClick={() => setState({ b: !state.b })}
                  aria-expanded="false"
                  aria-controls="collapse3"
                >
                  <img
                    alt=""
                    src={
                      state.b === true ? "images/minus.png" : "images/plus.png"
                    }
                    className="img-fluid"
                    style={{ width: 24, height: 24 }}
                  />
                </a>
              </p>
              <div class="collapse" id="collapse3">
                <div class="col-md-8 ">
                  Philips Diagnostic Center is located at NO 50B Oke Aro Street,
                  Akure, Ondo State, Nigeria.
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom col-md-8 collapse-container"></div>
            </section>

            <section>
              <p className="d-flex flex-row justify-content-between col-md-8">
                <div className="collapse-title">
                  What are your operating hours?
                </div>
                <a
                  class="btn"
                  data-bs-toggle="collapse"
                  href="#collapse4"
                  role="button"
                  onClick={() => setState({ c: !state.c })}
                  aria-expanded="false"
                  aria-controls="collapse4"
                >
                  <img
                    alt=""
                    src={
                      state.c === true ? "images/minus.png" : "images/plus.png"
                    }
                    className="img-fluid"
                    style={{ width: 24, height: 24 }}
                  />
                </a>
              </p>
              <div class="collapse" id="collapse4">
                <div class="col-md-8 ">
                  Our operating hours are 8am-6pm Mondays to Fridays, 8am-4pm on
                  Saturdays, we are closed on Sundays.
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom col-md-8 collapse-container"></div>
            </section>

            <section>
              <p className="d-flex flex-row justify-content-between col-md-8">
                <div className="collapse-title">
                  How do I get in touch with your facility?
                </div>
                <a
                  class="btn"
                  data-bs-toggle="collapse"
                  href="#collapse5"
                  role="button"
                  onClick={() => setState({ d: !state.d })}
                  aria-expanded="false"
                  aria-controls="collapse5"
                >
                  <img
                    alt=""
                    src={
                      state.d === true ? "images/minus.png" : "images/plus.png"
                    }
                    className="img-fluid"
                    style={{ width: 24, height: 24 }}
                  />
                </a>
              </p>
              <div class="collapse" id="collapse5">
                <div class="col-md-8 ">
                  You can contact our customer service on{" "}
                  <a className="link" href="tel:+2349135002227">
                    {" "}
                    +2349135002227, +2349135002226
                  </a>
                  . You can also send us an email us on
                  <a
                    className="link"
                    href="mailto:info@philipdiagnosticcenter.com"
                  >
                    {" "}
                    info@philipsdiagnosticcenter.com
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom col-md-8 collapse-container"></div>
            </section>

            <section>
              <p className="d-flex flex-row justify-content-between col-md-8">
                <div className="collapse-title">
                  Do I require a booking in order to have a test done?
                </div>
                <a
                  class="btn"
                  data-bs-toggle="collapse"
                  href="#collapse6"
                  onClick={() => setState({ e: !state.e })}
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapse6"
                >
                  <img
                    alt=""
                    src={
                      state.e === true ? "images/minus.png" : "images/plus.png"
                    }
                    className="img-fluid"
                    style={{ width: 24, height: 24 }}
                  />
                </a>
              </p>
              <div class="collapse" id="collapse6">
                <div class="col-md-8 ">
                  Yes, you can book to via our website, to have a test done. You
                  can also walk into our facility and and make paymnt for your
                  test.
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom col-md-8 collapse-container"></div>
            </section>

            <section>
              <p className="d-flex flex-row justify-content-between col-md-8">
                <div className="collapse-title">
                  Does Philips Diagnostic Center offer 24 hours service?
                </div>
                <a
                  class="btn"
                  data-bs-toggle="collapse"
                  onClick={() => setState({ f: !state.f })}
                  href="#collapse7"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapse7"
                >
                  <img
                    alt=""
                    src={
                      state.f === true ? "images/minus.png" : "images/plus.png"
                    }
                    className="img-fluid"
                    style={{ width: 24, height: 24 }}
                  />
                </a>
              </p>
              <div class="collapse" id="collapse7">
                <div class="col-md-8 ">
                  Philips Diagnostic Center is open form 8am to 6pm, Mondays to
                  Saturdays.
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom col-md-8 collapse-container"></div>
            </section>

            <section>
              <p className="d-flex flex-row justify-content-between col-md-8">
                <div className="collapse-title">
                  Can my physician perform a medical examination on me at your
                  facility?
                </div>
                <a
                  class="btn"
                  data-bs-toggle="collapse"
                  href="#collapse8"
                  onClick={() => setState({ g: !state.g })}
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapse8"
                >
                  <img
                    alt=""
                    src={
                      state.g === true ? "images/minus.png" : "images/plus.png"
                    }
                    className="img-fluid"
                    style={{ width: 24, height: 24 }}
                  />
                </a>
              </p>
              <div class="collapse" id="collapse8">
                <div class="col-md-8 ">
                  No, We have capable medical professionals on our team that can
                  examine you.
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom col-md-8 collapse-container"></div>
            </section>

            <section>
              <p className="d-flex flex-row justify-content-between col-md-8">
                <div className="collapse-title">
                  Can I obtain copies of my results?
                </div>
                <a
                  class="btn"
                  data-bs-toggle="collapse"
                  href="#collapse2"
                  role="button"
                  onClick={() => setState({ h: !state.h })}
                  aria-expanded="false"
                  aria-controls="collapse2"
                >
                  <img
                    alt=""
                    src={
                      state.h === true ? "images/minus.png" : "images/plus.png"
                    }
                    className="img-fluid"
                    style={{ width: 24, height: 24 }}
                  />
                </a>
              </p>
              <div class="collapse" id="collapse2">
                <div class="col-md-8 ">
                  Once your test results are ready, your final report is sent to
                  the email address you filled upon registration. You can also
                  walk into our facility and pick up a hardcopy of your report.
                </div>
              </div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2 border-bottom col-md-8 collapse-container"></div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
