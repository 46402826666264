import React from "react";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";

const Pathology = () => {
  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pathology-philipsDiagnostic.com</title>
        <link rel="canonical" href="/pathology" />
      </Helmet>
      <Header />
      <div className="container-fluid path-header pt-3 pb-5">
        <div className="row">
          <div className="col-md-4 offset-md-2 pt-5 path-blur">
            <h1 className="service-title">Pathology</h1>
            <div className="service-subTitle">
              Through meticulous analysis of tissues and fluids, our pathology
              services provide crucial insights, guiding accurate diagnoses and
              personalized treatment plans
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-3 offset-md-1">
            <h6 className="mt-20 d-md-none d-lg-none">
              Pathology <Dot color={"#D21D22"} />{" "}
            </h6>
            <img alt="" src="images/test.png" className="img-fluid mt-md-5" />
          </div>
          <div className="col-md-6  ps-5-md">
            <h6 className="mt-20 d-none d-md-block">
              Pathology <Dot color={"#D21D22"} />{" "}
            </h6>
            <div className="text-start mt-2 pe-sm-2 mt-3-sm lh-28">
              A medical discipline focused on scrutinizing organs, tissues, and
              bodily fluids for disease diagnosis, is expertly administered at
              Philips Diagnostic Center. Our meticulous pathological process
              involves comparing normal human structures and functions with
              anomalies detected during our procedures. Every sample collected
              undergoes thorough examination to facilitate precise diagnoses.
              Equipped with state-of-the-art technology, our facility ensures
              accurate and swift results for client examinations. The
              comprehensive scope of our Pathology services encompasses
              Chemistry, Haematology, Hormonal assay, Histopathology/
              Histochemistry, Clinical Pathology, Microbiology/ Parasitology,
              Histobiopsy, and Serology, reflecting our commitment to delivering
              top-
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselEp"
        className="carousel carousel-light slide mt-50 mb-50"
        data-bs-ride="carousel"
        data-bs-wrap="true"
      >
        <div className="carousel-inner">
          {/* slider item 1 */}
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="container">
              <div className="row ">
                <div className="col-md-3 ps-md-5 order-md-2">
                  <h6 className="mt-20 d-md-none d-lg-none text-dark">
                    Pathology <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <h4 className="text-danger  d-md-none d-lg-none">
                    Molecular Biology/PCR
                  </h4>
                  <img
                    alt=""
                    src="images/molecular.png"
                    className="img-fluid mt-5"
                  />
                </div>
                <div className="col-md-6  offset-md-1 order-md-1">
                  <h6 className="mt-20 d-none d-md-block text-dark">
                    Pathology <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <h4 className="text-danger d-none d-md-block">
                    Molecular Biology/PCR
                  </h4>
                  <div className="text-start mt-2 text-dark pe-sm-2 mt-3-sm lh-28">
                    At Philips Diagnostic Center, we leverage modern molecular
                    biology techniques, including Polymerase Chain Reaction
                    (PCR), to enhance Diagnostic precision. Molecular biology
                    plays a pivotal role in our advanced Diagnostic, allowing us
                    to analyze genetic material at the molecular level. PCR, a
                    cornerstone of molecular biology, enables the amplification
                    of specific DNA sequences, aiding in the detection of
                    infections, genetic disorders, and various diseases. This
                    innovative approach at our center ensures accuracy and
                    efficiency in diagnosing conditions, contributing to
                    personalized and effective treatment plans. Our commitment
                    to incorporating molecular biology technologies underscores
                    our dedication to delivering state-of-the-art Diagnostic
                    solutions for optimal patient care.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> */}
      </div>
      <div className="mt-50 mb-50" style={{ height: 50 }}></div>
      <Footer />
    </div>
  );
};

export default Pathology;
