import React from "react";
import { Header, Footer } from "../../custom";
import { Helmet } from "react-helmet";

const Privacy = () => {
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy - philipDiagnostic.com</title>
        <link rel="canonical" href="/privacy" />
      </Helmet>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center privacy align-items-center d-flex justify-content-center">
            Privacy Policy
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 lh-32">
            This privacy policy is applicable to the operations of Philips
            Diagnostic Center (https://www.philipsDiagnosticcenter.com/), which
            is owned and managed by Philips Diagnostic Center. It delineates how
            we, at Philips Diagnostic Center ("we," "us"), gather personal
            information from you, elucidates your rights regarding this
            information, and outlines your choices concerning the utilization of
            your personal data when you engage with our services.
          </div>
        </div>

        <div className="row mt-5 mb-5 lh-5">
          <div className="col-md-12">
            We encourage you to take a few moments to review our privacy policy,
            providing valuable insights into the nature of personal information
            we collect from you. This document elucidates how we employ and
            share the personal information you provide us, particularly through
            our mobile application. Your use of our mobile application implies
            your acknowledgment and acceptance of this privacy policy,
            encompassing our utilization of your personal data as delineated
            within. Given the evolving nature of privacy considerations, we
            reserve the right to periodically review and amend this privacy
            policy. We strongly advise you to regularly revisit this policy for
            updates.
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <ul class="list-group">
              <li class="list-group-item border-0">
                <b>Information Collection:</b> We collect only essential
                personal and medical information required for Diagnostic
                purposes. This may include but is not limited to, name, email
                address, contact details, address, next of kin details, social
                history, surgical history, medical history, and test results.
              </li>
              <li class="list-group-item border-0">
                {" "}
                <b>Data Security:</b> All patient information is stored securely
                in compliance with industry standards. We employ robust security
                measures to protect against unauthorized access, disclosure,
                alteration, or destruction of personal data, thereby ensuring
                confidentiality and security of our patients' data.
              </li>
              <li class="list-group-item border-0">
                {" "}
                <b>Usage Limitation:</b> Patient information is strictly used
                for Diagnostic and healthcare-related purposes. We do not share,
                sell, or disclose any personal information to third parties
                without explicit consent, unless required by law.
              </li>
              <li class="list-group-item border-0">
                <b>Data Retention:</b> Patient records are retained for the
                period necessary to fulfill the Diagnostic purposes for which
                they were collected. After this period, information is securely
                disposed of in accordance with applicable regulations
              </li>
              <li class="list-group-item border-0">
                <b>Confidentiality:</b> Our staff undergoes training to uphold
                strict confidentiality standards. They are bound by
                confidentiality agreements to ensure the privacy of patient
                information.
              </li>
              <li class="list-group-item border-0">
                <b>Access and Correction:</b> Patients have the right to access
                their personal information and request corrections if necessary.
                Requests for access or corrections can be made through our
                designated channels.
              </li>
              <li class="list-group-item border-0">
                <b>Compliance:</b> We adhere to all applicable data protection
                laws and regulations. Our Privacy Policy is regularly reviewed
                and updated to maintain compliance with evolving legal
                standards.
              </li>
            </ul>
          </div>
        </div>

        <div className="row mt-3 mb-5">
          <div className="col-md-12">
            By choosing Philips Diagnostic Center, patients implicitly agree to
            the terms outlined in this Privacy Policy. Any updates or changes to
            this policy will be communicated promptly. For further clarification
            or inquiries, please contact our designated privacy officer.
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
