import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "./about";
import Contact from "./contact";
import Home from "./home";
import Services from "./services";
import FAQ from "./faq";
import Imaging from "./medical";
import Gallery from "./gallery";
import DNA from "./dna";
import Dialysis from "./dialysis";
import Care from "./cardiacCare";
import Ambulance from "./ambulance";
import Pathology from "./pathology";
import Drug from "./drugAchohol";
import Privacy from "./privacy";
import HealthCarePackages from "./healthPackages";
import Members from "./Members/Index";
import { ErrorPage } from "../custom";

const Root = () => (
  <Routes>
    <Route exact path="/" element={<Home />} />

    <Route path="/about" element={<About />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/services" element={<Services />} />
    <Route path="/faq" element={<FAQ />} />
    <Route path="/healthcare-packages" element={<HealthCarePackages />} />
    <Route path="/members" element={<Members />} />
    <Route path="/imaging" element={<Imaging />} />
    <Route path="/pathology" element={<Pathology />} />
    <Route path="/dna-testing" element={<DNA />} />
    <Route path="/dialysis" element={<Dialysis />} />
    <Route path="/ambulance" element={<Ambulance />} />
    <Route path="/care" element={<Care />} />
    <Route path="/drug" element={<Drug />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/gallery" element={<Gallery />} />

    <Route path="*" element={<ErrorPage />} />
  </Routes>
);

export default Root;
