import React from "react";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";

const Drug = () => {
  return (
    <div className="mainBg">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Drug and Alcohol - philipsDiagnostic.com</title>
        <link rel="canonical" href="/drugAchohol" />
      </Helmet>
      <Header />
      <div className="container-fluid drug-header pt-3">
        <div className="row">
          <div className="col-md-4 offset-md-2 pt-5 drug-blur">
            <h1 className="service-title">Drug and Alcohol</h1>
            <div className="service-subTitle">
              At Philips Diagnostic Center, we provide comprehensive testing
              services for drug and alcohol screenings. Our state-of-the-art
              facilities and expert team ensure accurate and confidential
              results, promoting a safer and healthier community
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselEp"
        className="carousel carousel-light slide mt-50 mb-50"
        data-bs-ride="carousel"
        data-bs-wrap="true"
      >
        <div className="carousel-inner">
          {/* slider item 1 */}
          <div className="carousel-item active" data-bs-interval="10000">
            <div className="container">
              <div className="row ">
                <div className="col-md-3 offset-md-1">
                  <h6 className="mt-20 d-md-none d-lg-none text-dark">
                    Drug and Alcohol <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <h4 className="text-danger  d-md-none d-lg-none">
                    Drug and Alcohol Testing
                  </h4>
                  <img
                    alt=""
                    src="images/alcohol.png"
                    className="img-fluid mt-5"
                  />
                </div>
                <div className="col-md-6  ps-md-5">
                  <h6 className="mt-20 d-none d-md-block text-dark">
                    Drug and Alcohol <Dot color={"#D21D22"} />{" "}
                  </h6>
                  <h4 className="text-danger d-none d-md-block">
                    Drug and Alcohol Testing
                  </h4>
                  <div className="text-start mt-2 text-dark ps-sm-2 pe-sm-2 mt-3-sm lh-28">
                    Philips Diagnostic Center offers comprehensive drug and
                    alcohol testing services. Our state-of-the-art facility
                    employs advanced methodologies to detect and analyze the
                    presence of drugs of abuse and alcohol in biological
                    samples. This service is crucial for various purposes,
                    including workplace screenings, legal requirements, and
                    personal health assessments. Philips Diagnostic Center's
                    commitment to excellence extends to drug and alcohol
                    testing, emphasizing precision, confidentiality, and the
                    overall well-being of individuals undergoing these
                    Diagnostic assessments.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselEp"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> */}
      </div>
      <div className="mt-50 mb-50" style={{ height: 50 }}></div>

      <Footer />
    </div>
  );
};

export default Drug;
