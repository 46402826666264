import React, { useRef, useEffect } from "react";
import { Header, Footer, Dot } from "../../custom";
import { Helmet } from "react-helmet";
import clockHand from "../../Assets/clockHand.png";

const About = () => {
  const sectionRef = useRef(null);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (window.location.hash === "#section") {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About - philipsDiagnostic.com</title>
        <link rel="canonical" href="/about" />
      </Helmet>
      <Header />

      <div className="container-fluid about-header pt-3">
        <div className="row">
          <div className="col-md-3 offset-md-2 pt-5 health-blur">
            <h1 className="health-title">HEALTHCARE</h1>
            <div className="health-subTitle">
              Welcome to Philips Diagnostic Centre is your trusted partner in
              health and wellness, located in the heart of Akure, Ondo State,
              Nigeria. At Philips Diagnostic Centre, we are dedicated to
              providing top-notch Diagnostic services with a focus on accuracy,
              efficiency, and patient care
            </div>
          </div>
          <div className="col-md-6 offset-md-1 d-none d-md-block">
            <img alt="" src="images/setoscope.png" />
          </div>
        </div>
      </div>

      <div className="container-fluid about-us pt-5">
        <div className="row">
          <div className="col-md-3 offset-md-2">
            <span className="title d-dm-none d-lg-none ps-sm-2 pe-sm-2">
              About Us <Dot color={"#D21D22"} />{" "}
            </span>{" "}
            <br />
            <div className="text-danger column-title  d-dm-none d-lg-none mb-3 mt-1 ps-sm-2 pe-sm-2">
              Philips Diagnostic Center
            </div>
            <img
              alt=""
              src="images/room.png"
              className="img-fluid img-room ps-sm-2 pe-sm-2"
            />
            <div className="img-cont mt-2">
              <img
                alt=""
                src="images/room1.png"
                className="img-fluid subroom d-none d-md-block"
              />
              <img
                alt=""
                src="images/room2.png"
                className="img-fluid subroom d-none d-md-block"
              />
            </div>
          </div>
          <div className="col-md-5 ps-md-4">
            <span className="title d-none d-dm-block">
              About Us <Dot color={"#D21D22"} />{" "}
            </span>{" "}
            <br />
            <span className="text-danger column-title d-none d-dm-block">
              Philips Diagnostic Center
            </span>
            <div className="justify ps-sm-2 pe-sm-2">
              Philips Diagnostic Centre, Akure is nestled in the heart
              of Ondo State, Nigeria and at our state-of-the-art facility, we
              prioritize your health and well-being through cutting-edge
              Diagnostic services.
            </div>
            <div className="justify ps-sm-2 pe-sm-2 mt-3-sm">
              Founded with a commitment to excellence, we bring together a team
              of skilled healthcare professionals and advanced technology to
              provide accurate and timely Diagnostic solutions.
            </div>
            <div className="justify ps-sm-2 pe-sm-2 mt-3-sm ">
              Our center is equipped with the latest Diagnostic equipment,
              ensuring precision in medical imaging and Diagnostic. As a trusted
              healthcare partner in the community, we strive to create a warm
              and welcoming environment for our patients. Our dedicated team is
              committed to delivering compassionate care while upholding the
              highest standards of medical excellence.
            </div>
            <div className="justify ps-sm-2 pe-sm-2  mt-3-sm">
              Whether you're seeking routine check-ups, specialized Diagnostic,
              or preventive screenings, Philips Diagnostic Centre is your
              dependable ally in promoting a healthier tomorrow. We embrace a
              patient-centric approach, understanding the importance of reliable
              Diagnostic in healthcare decision-making.
            </div>
            <div className="justify ps-sm-2 pe-sm-2 mt-3-sm">
              At Philips Diagnostic Centre, we stand at the forefront of medical
              innovation, fostering a culture of continuous improvement to meet
              and exceed the evolving healthcare needs of our community. Your
              well-being is our priority, and we look forward to serving you
              with integrity, expertise, confidentiality, and a genuine
              commitment to your health journey.
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid lower-layer">
        <div style={{ alignItems: "center" }} className="row mb-5 pb-3">
          <div className="col-md-4 offset-md-2 ">
            <img
              alt=""
              src="images/mission.jpg"
              className="img-fluid d-md-none d-lg-none d-ls-block d-sm-block"
            />
            <h4 className="text-danger ps-sm-2 pe-sm-2">Our Mission</h4>
            <div className="ps-sm-2 pe-sm-2 lh-28">
              At Philips Diagnostic Centre, our mission is to empower
              individuals with precise and timely Diagnostic insights, fostering
              a healthier community in Akure, Ondo State, Nigeria. Committed to
              excellence, we leverage state-of-the-art Diagnostic technology and
              a dedicated team of healthcare professionals to provide accurate
              and compassionate Diagnostic services.
            </div>

            <div className="ps-sm-2 pe-sm-2 mt-4-sm lh-28">
              Our focus extends beyond Diagnostic; we aim to enhance overall
              well-being by delivering personalized care, promoting preventive
              health measures, and contributing to the advancement of healthcare
              standards in our community. Through innovation, integrity, and a
              patient-centric approach, Philips Diagnostic Centre aspires to be
              a trusted healthcare partner, empowering individuals to make
              informed decisions about their health and ensuring a path to a
              brighter, healthier future for all.
            </div>
          </div>

          <div className="col-md-3 ps-md-3">
            <img
              style={{ borderRadius: "5px" }}
              alt=""
              src="images/mission.jpg"
              className="img-fluid d-none d-md-block"
            />
          </div>
        </div>

        <div style={{ alignItems: "center" }} className="row mt-5 pt-2">
          <div className="col-md-3  offset-md-2">
            <img
              style={{ borderRadius: "5px" }}
              alt=""
              src="images/vision.jpg"
              className="img-fluid"
            />
          </div>
          <div className="col-md-4">
            <h4 className="text-danger ps-sm-2 pe-sm-2">Our Vision</h4>
            <div className="ps-sm-2 pe-sm-2 lh-28">
              Philips Diagnostic Center envisions a future where health meets
              innovation, and every individual in Akure, Ondo State, Nigeria
              experiences superior Diagnostic care. As a leading hub of
              excellence, our vision is to redefine healthcare through
              cutting-edge technology, personalized patient experiences, and a
              commitment to advancing medical Diagnostic.
            </div>
            <div className="ps-sm-2 pe-sm-2 mt-4-sm lh-28">
              We strive to be the trusted catalyst for proactive health
              management, contributing to a community where timely, precise, and
              compassionate Diagnostic empower individuals to lead healthier
              lives. At Philips Diagnostic Center, our vision is to stand at the
              forefront of transformative healthcare, setting new standards in
              accessibility, innovation, and patient well-being.
            </div>
          </div>
        </div>

        <div ref={sectionRef} className="container mt-5 pb-5">
          <div className="">
            <h4 className="text-danger pe-sm-2">Our Core Values</h4>
            <div className="pe-sm-2">
              <p>Our Guiding Lights:</p>
              <ul style={{ width: "70%" }} className="list-grp flex">
                <li className="values"> Excellence</li>
                <li className="values"> Integrity</li>
                <li className="values"> Professionalism </li>
                <li className="values"> Patient Centricity</li>
                <li className="values">Innovation</li>
              </ul>
            </div>
          </div>
          <div
            style={{ margin: "3% 0 0 0" }}
            className="flex bg-white membersCont"
          >
            <div className="p-5">
              <div className="layout-title mb-4">Opening Hours</div>
              <div className="layout-subTitle mb-2">Mondays - Fridays</div>
              <div className="layout-text">8am - 6pm</div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom ps-3"></div>
              <div className="layout-subTitle mb-2 mt-3">Saturdays</div>
              <div className="layout-text">8am - 4pm</div>
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom ps-3"></div>
              <div className="layout-subTitle mb-2 mt-3">Sundays</div>
              <div className="layout-text">Closed</div>
            </div>

            <div>
              <img width="100%" alt="" src={clockHand} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
